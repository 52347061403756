'use client'
import { memo, useMemo } from 'react'
import { usePathname } from 'next/navigation'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, designGrid, getP40, getP15, getMargin100, getMargin15 } from '@/styles'
import { useTranslator } from '@/hooks'
import { SliderFlickity } from '../SliderFlickity'
import { Card } from './Card'

interface ISectionProjects {
  data: any,
  className?: string
  isRelated?: boolean
}

const getProjects = (data, pathname) => {
  if(!data) return []
  const splited = pathname.split('/project/')
  const _slug = splited[splited.length - 1]

  return data.filter(({ slug }) => slug !== _slug).map((project, idx) => <Card key={idx} data={project} />)
}

export const SectionProjects = memo(({ data, className = '', isRelated = false }:ISectionProjects) => {
  const { t } = useTranslator()
  const pathname = usePathname()
  const Projects = useMemo(() => getProjects(data, pathname), [data, pathname])

  return (
    <Section initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ once: true, margin: '-30%' }} className={className}>
      <h2>{t(`home.${isRelated ? 'section_relateds' : 'section_projects'}.title`)}</h2>
      <p>{t(`home.${isRelated ? 'section_relateds' : 'section_projects'}.description`)}</p>
      <SliderFlickity reloadOnUpdate={true} options={{ cellAlign: 'left' }}>{Projects}</SliderFlickity>
    </Section>
  )
})

const Section = styled(motion.section)`
  ${designGrid({})}
  overflow: hidden;
  padding: ${vw(50, 'mobile')} 0 ${vw(100, 'mobile')};
  opacity: 0;

  ${mq.greaterThan('tablet')} {
    padding: ${vw(100, 'desktop')} 0 ${vw(140, 'desktop')};
  }

  h2 {
    ${getP15()}
    text-align: left;
    grid-column: 1 / span 5;

    ${mq.greaterThan('tablet')} {
      grid-column: 1 / span 10;
    }

    &:last-of-type {
      ${getMargin15()}
    }
  }

  p {
    ${getP40()}
    grid-column: 1 / span 6;

    ${mq.greaterThan('tablet')} {
      grid-column: 1 / span 10;
    }

    &:last-of-type {
      margin-bottom: ${vw(60, 'mobile')};

      ${mq.greaterThan('tablet')} {
        ${getMargin100()}
      }
    }
  }

  .slider {
    grid-column: 1 / span 6;

    ${mq.greaterThan('tablet')} {
      grid-column: 1 / span 12;
    }
  }
`
