'use client'
// import Link from 'next/link'
import { useParams } from 'next/navigation'
import styled from 'styled-components'
// import { useCopyText } from '../context'
import { motion } from 'framer-motion'
import { mq, vw, getP30, getP15 } from '@/styles'
import { Figure } from '../Figure'
import { LOCALE } from '@/utils'

const hardcodedSlug = {
  es: {
    hellomamma: 'https://hellomamma.es', // B2C headless ecommerce / ver
    gigistudios: 'https://gigistudios.com/es/ESP', // B2C + B2B headless ecommerce / ver
    tria: 'https://tria.mobles114.com', // Furniture system design icon configurator / ver
    safeguru: 'https://safeguru.com', // B2C + B2B headless ecommerce / ver
    inditex: 'https://www.massimodutti.com/es', // Massimo Dutti Apps and website / ver
    aicam: 'https://aicam.studio/es',
    mammafiore: 'https://mammafiore.es',
    losiento: 'https://losiento.studio'
  },
  en: {
    hellomamma: 'https://hellomamma.co.uk', // B2C headless ecommerce / view
    gigistudios: 'https://gigistudios.com/en/US', // B2C + B2B headless ecommerce / view
    tria: 'https://tria.mobles114.com', // Furniture system design icon configurator / view
    safeguru: 'https://safeguru.co.uk', // B2C + B2B headless ecommerce / view
    inditex: 'https://www.massimodutti.com',
    aicam: 'https://aicam.studio',
    mammafiore: 'https://mammafiore.co.uk',
    losiento: 'https://losiento.studio'
  }
}

const getSlug = (client, locale) => {
  let clientKey = null
  switch(client) {
    case 'HelloMamma':
      clientKey = 'hellomamma'
      break
    case 'GIGIStudios':
      clientKey = 'gigistudios'
      break
    case 'Mobles 114':
      clientKey = 'tria'
      break
    case 'Safeguru':
      clientKey = 'safeguru'
      break
    case 'AiCam':
      clientKey = 'aicam'
      break
    case 'Mammafiore':
      clientKey = 'mammafiore'
      break
    case 'Losiento':
      clientKey = 'losiento'
      break
    case 'Inditex':
    default:
      clientKey = 'inditex'
      break
  }

  return hardcodedSlug[locale ?? LOCALE][clientKey]
}

export const Card = ({ data }) => {
  const { client, logo, image, media, name } = data
  console.log('data', data)
  const { lang }  = useParams()

  if(!data) {
    console.warn('No data for <Card />')
    return null
  }

  const mediaData = {
    type: 'image',
    alt: media.alt,
    m: {
      src: media.default['2x'].regular,
      size: media.size,
    }
  }

  return (
    <Article>
      {media ?
        <Figure className='img' media={mediaData} lazy={false} />
        :
        <figure>
          {image ?
            // eslint-disable-next-line @next/next/no-img-element
            <img className='img' src={image} alt='Card image' />
            :
            <div className='img' />
          }
        </figure>
      }
      <a href={getSlug(client, lang)} rel='noreferrer' target='_blank'>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        {logo && <img className='logo' src={logo.url} alt={logo.alt} />}
        <div className='text-wrapper'>
          <h3><span className='srt-only'>{client}</span>{name}</h3>
          <span className='span'>{lang === 'es' ? 'Ver' : 'View'}</span>
        </div>
      </a>
      {/* </Link> */}
    </Article>
  )
}

const Article = styled(motion.article)`
  margin-right: ${vw(9.5, 'mobile')};
  overflow: hidden;
  border-radius: ${vw(6.96, 'mobile')};
  position: relative;

  ${mq.greaterThan('tablet')} {
    transition: transform 700ms cubic-bezier(0.12, 0.07, 0.2, 1), box-shadow 700ms cubic-bezier(.25, .1, .25, 1);
    margin-right: ${vw(15, 'desktop')};
    border-radius: ${vw(10, 'desktop')};

    &:hover {
      transform: scale(1.03);
      box-shadow: 0 ${vw(35, 'desktop')} ${vw(45, 'desktop')}  rgba(0, 0, 0, .2);

      .img {
        transform: scale(1);
      }

      a {
        transform: scale(0.97);

        .logo {
          transform: scale(1.03);
        }

        h3 {
          transform: translateY(${vw(-45, 'desktop')});
        }

        .span {
          opacity: 1;
          transform: translateY(${vw(-15, 'desktop')});
        }
      }
    }
  }

  a {
    align-items: flex-start;
    color: white;
    display: flex;
    flex-direction: column;
    height: ${vw(454, 'mobile')};
    width: ${vw(289, 'mobile')};
    justify-content: space-between;
    padding: ${vw(29, 'mobile')};
    position: relative;
    text-decoration: none;

    ${mq.greaterThan('tablet')} {
      transition: transform 700ms cubic-bezier(0.12, 0.07, 0.2, 1);
      height: ${vw(650, 'desktop')};
      width: ${vw(414, 'desktop')};
      padding: ${vw(40, 'desktop')};
    }

    .logo {
      transition: 150ms transform cubic-bezier(0.12, 0.07, 0.2, 1);
      width: auto;
      z-index: 2;
    }
  }

  figure {
    background-color: var(--color-text);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(1.1);
    transition: transform 700ms cubic-bezier(0.12, 0.07, 0.2, 1);

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .text-wrapper {
    position: relative;
    z-index: 2;

    > span {
      ${getP15()};
      bottom: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      transition: transform 500ms cubic-bezier(.25, .1, .25, 1), opacity 500ms cubic-bezier(.25, .1, .25, 1);
    }
  }

  h3 {
    ${getP30()};
    text-align: left;
    transition: transform 500ms cubic-bezier(.25, .1, .25, 1);
  }
`
