'use client'
import { memo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, hideScrollbar, getP93_2 } from '@/styles'
import { useTranslator } from '@/hooks'

interface IMarqueeSlider {
  uniqueClass?: any,
  inverted?: boolean,
}

export const MarqueeSlider = memo<IMarqueeSlider>(({ uniqueClass = 'marquee-item' , inverted = false }) => {
  const { t } = useTranslator()
  return (
    <>
      <MarqueeSliderWrapper className='marquee' $inverted={inverted}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <motion.p className={uniqueClass}  animate='animate'  variants={variantsText} >&nbsp;<img src='/images/svg/i--prize.svg' alt='prize icon' /> {t('home.section_prize.title')} </motion.p> <motion.p className={uniqueClass}  animate='animate'  variants={variantsText} >&nbsp;<img src='/images/svg/i--prize.svg' alt='prize icon' /> {t('home.section_prize.title')} </motion.p>
      </MarqueeSliderWrapper>
    </>
  )
})


const MarqueeSliderWrapper = styled.div<any>`
  ${hideScrollbar()}
  background-color:none;
  display: flex;
  overflow-x: hidden;
  padding: ${vw(20, 'mobile')} 0;
  position: relative;

  ${mq.greaterThan('tablet')} {
    padding: ${vw(100, 'desktop')} 0;
  }

  p {
    ${getP93_2()}
    color: ${({ $inverted }) => $inverted ? 'black' : 'var(--color-white)'};
    white-space: nowrap;

    img {
      height: ${vw(58, 'mobile')};
      width: ${vw(58, 'mobile')};

      ${mq.greaterThan('tablet')} {
        height: ${vw(128, 'desktop')};
        width: ${vw(128, 'desktop')};
      }
    }
  }
`

const variantsText = {
  animate: {
    x: '-100%',
    transition: {
      duration: 10,
      repeat: Infinity,
      ease: 'linear'
    }
  }
}
